/*
 PRODUCT: BswUserSite

 Copyright © 2023 BlueSky Wireless Limited. All rights reserved.
 */

'use strict';

import './polyfills.js';
import './bootstrap.js';
import flatpickr from 'flatpickr';
import SortOrdering from './sort_ordering.js';
import PaginationGoToPage from './PaginationGoToPage.js';
import SearchClearer from './SearchClearer.js';
import {onReady} from './helpers';
import {monitorInputToggles} from './InputGroupToggler';
import bsCustomFileInput from 'bs-custom-file-input';
import $ from 'jquery';

(function (document, window) {

    onReady(document, () => {
        bsCustomFileInput.init();

        configureDateTimeOffsetForDateTimeLocalElements(document);

        const flatpickrOptions = {
            enableTime: true,
            dateFormat: 'Y-m-d\\TH:i',
            altInput: true,
            altFormat: 'd/m/Y H:i',
            allowInput: true,
            minuteIncrement: 1,
            time_24hr: true
        };

        flatpickr('input[type=datetime-local]:not(.range-start):not(.range-end):not(.range-display):not(.disable-flatpickr)', flatpickrOptions);

        flatpickrOptions.inline = true;

        flatpickrOptions.appendTo = document.getElementById('append_flatpickr_1');
        flatpickrOptions.onChange = [function (selectedDates) {
            window.flatpickr_RangeDisplay.setDate([window.flatpickr_RangeDisplay.selectedDates[0], selectedDates[0]], false);
        }];

        window.flatpickr_EndDate = flatpickr('input.range-end[type=datetime-local]', flatpickrOptions);

        flatpickrOptions.appendTo = document.getElementById('append_flatpickr_0');
        flatpickrOptions.onChange = [function (selectedDates) {
            window.flatpickr_RangeDisplay.setDate([selectedDates[0], window.flatpickr_RangeDisplay.selectedDates[1]], false, 'Y-m-d\\TH:i');
        }];

        flatpickrOptions.clickOpens = false;
        flatpickrOptions.allowInput = true;
        window.flatpickr_StartDate = flatpickr('input.range-start[type=datetime-local]', flatpickrOptions, 'Y-m-d\\TH:i');

        flatpickrOptions.inline = false;
        flatpickrOptions.mode = 'range';
        flatpickrOptions.onChange = [];

        flatpickrOptions.onValueUpdate = [function (selectedDates) {
            window.flatpickr_StartDate.setDate(selectedDates[0]);
            window.flatpickr_EndDate.setDate(selectedDates[1]);
        }];
        window.flatpickr_RangeDisplay = flatpickr('input.range-display[type=datetime-local]', flatpickrOptions);

        let rangeDisplayEle = document.getElementById('rangeDisplay');
        if (rangeDisplayEle) {
            rangeDisplayEle.onfocusout = function () {
                document.getElementById('rangeDisplay').dispatchEvent(
                    new KeyboardEvent('keydown', {
                        bubbles: true,
                        cancelable: true,
                        keyCode: 13
                    })
                );
            };
        }

        let runAtSelectionEle = document.getElementById('runAtSelection');
        if (runAtSelectionEle) {
            runAtSelectionEle.onfocusout = function () {
                document.getElementById('runAtSelection').dispatchEvent(new KeyboardEvent('keydown', {
                    bubbles: true, cancelable: true, keyCode: 13
                }));
            };
        }

        let clearBtn = document.getElementById('clearBtn');

        if (null !== clearBtn) {
            clearBtn.addEventListener('click', evt => {
                evt.preventDefault();
                const element = document.querySelector('input#next_run_at');
                element._flatpickr.clear(true)
            })
        }

        let scheduledSave = document.getElementById('scheduled-save');


        let tab;

        let scheduledTab = document.getElementById('scheduledTab');
        if (null !== scheduledTab) {
            scheduledTab.addEventListener('click', evt => {
                tab = document.querySelector('button[aria-selected="true"]').innerText;
            })
        }
        if (null !== scheduledSave) {
            scheduledSave.addEventListener('click', evt => {
                if (tab === 'Recurring Message') {
                    const element = document.querySelector('input#next_run_at');
                    element._flatpickr.clear(true);
                } else if (tab === 'One-off Message') {
                    document.getElementById('recurrence_cron').value = '';
                }
            })
        }

        SortOrdering.init();

        dismissAlerts(document);

        const paginationGoToPage = new PaginationGoToPage(document);
        paginationGoToPage.init();

        const searchClearer = new SearchClearer(document);
        searchClearer.init();

        monitorInputToggles();

        monitorElementEnablements(document, window);

        stopAudioOnModalClose(document, $);

        removeLoadingSpinner(document);

        handleBottomFixedAlert(document);
    })
})(document, window);

function configureDateTimeOffsetForDateTimeLocalElements(document) {
    const elements = document.querySelectorAll('input[type=datetime-local]');

    const now = new Date();
    // offset is in minutes, and is UTC - now. For BST, this results in -60, where we want +60, so negate the value
    const offset = -(now.getTimezoneOffset());

    Array.from(elements).forEach(el => {
        const elementName = el.getAttribute('name');

        const tzOffsetElementName = `${elementName}__tz_offset`;

        const existingElement = document.getElementsByName(`input[name = "${tzOffsetElementName}"]`);

        if (existingElement.length > 0) {
            return;
        }

        const tzOffsetElement = document.createElement('input');
        tzOffsetElement.setAttribute('type', 'hidden');
        tzOffsetElement.setAttribute('name', tzOffsetElementName);
        tzOffsetElement.setAttribute('value', offset);

        el.parentNode.insertBefore(tzOffsetElement, el);
    });
}

function dismissAlerts(document) {
    Array.from(document.querySelectorAll('.alert-dismissible [data-dismiss="alert"]')).forEach(el => {
        el.addEventListener('click', evt => {
            evt.preventDefault();

            const parent = evt.target.closest('.alert-dismissible');

            if (parent) {
                parent.remove();
            }

            return false;
        });

        // setTimeout(() => {
        //     const parent = el.closest('.alert-dismissible');
        //
        //     if (parent) {
        //         parent.remove();
        //     }
        //
        //     return false;
        // }, 15000)
    });
}

function monitorElementEnablements(document, window) {
    Array.from(document.querySelectorAll('[data-time-to-wait-before-enable]')).forEach(el => {
        const waitTime = el.dataset.timeToWaitBeforeEnable;

        window.setTimeout(() => {
            el.disabled = false;
        }, waitTime);
    })
}

function stopAudioOnModalClose(document, $) {
    $('.modal').on('hide.bs.modal', e => {
        const audioElements = $(e.target).find('audio');

        audioElements.each((idx, el) => {
            el.pause();
            el.currentTime = 0;
        });
    });
}

function removeLoadingSpinner(document) {
    let spinner = document.getElementById('bsw__spinner__container');

    if (spinner) {
        spinner.remove();
    }

    document.documentElement.style.overflow = 'auto';
}

function handleBottomFixedAlert(document) {
    const observerConfig = {
        subtree: true,
        childList: true,
    };

    function alterCurrentPadding() {
        const bottomFixedAlert = document.querySelectorAll('.alert.alert--flash');

        let totalHeight = 0;

        bottomFixedAlert.forEach(el => {
            const style = window.getComputedStyle(el);
            if (style.display !== 'none') {
                totalHeight += el.clientHeight;
            }
        });

        document.body.style.paddingBottom = `${totalHeight}px`;
    }

    alterCurrentPadding();

    const observer = new MutationObserver(function (mutations) {
        let anyChangesToAlerts = false;

        mutations.forEach(mutation => {
            if (mutation.type !== 'childList') {
                return;
            }

            mutation.addedNodes.forEach(node => {
                if ((node.classList && node.classList.contains('alert--flash')) || (node.querySelectorAll && node.querySelectorAll('.alert--flash').length !== 0)) {
                    anyChangesToAlerts = true;
                }
            });

            mutation.removedNodes.forEach(node => {
                if ((node.classList && node.classList.contains('alert--flash')) || (node.querySelectorAll && node.querySelectorAll('.alert--flash').length !== 0)) {
                    anyChangesToAlerts = true;
                }
            });
        });

        if (anyChangesToAlerts) {
            alterCurrentPadding();
        }
    });

    observer.observe(document.body, observerConfig);
}
