/*
 PRODUCT: BswUserSite

 Copyright © 2023 BlueSky Wireless Limited. All rights reserved.
 */

export function toTitleCase(str)
{
    return str.toLowerCase().split(' ').map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

export function getParams(query)
{
    if (!query) {
        return {};
    }

    return (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params, param) => {
            let [key, value] = param.split('=');
            params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
            return params;
        }, {});
}

export function getQueryParams()
{
    return getParams(window.location.search);
}

export function onReady(document, callback)
{
    if (document.readyState === 'interactive' || document.readyState === 'complete') {
        callback();
    } else {
        document.addEventListener('DOMContentLoaded', callback);
    }
}

/**
 * Return a promise that is resolved after a given delay, or after being cancelled.
 *
 * @param  {number} duration The delay, in milliseconds.
 * @param  {AbortSignal|null} signal An optional AbortSignal to cancel the delay.
 *
 * @return {Promise<void>} A promise that is either resolved after the delay, or rejected after the signal is cancelled.
 */
export function asyncSleep(duration, signal)
{
    function isAbortSignal(val) {
        return typeof val === 'object' && val.constructor.name === AbortSignal.name;
    }

    if (signal !== null && isAbortSignal(signal) && signal.aborted) {
        return Promise.reject(new DOMException('Sleep aborted.', 'AbortError'));
    }

    return new Promise(function (resolve, reject) {
        let timeoutHandle = null;

        function handleAbortEvent()
        {
            if (timeoutHandle !== null) {
                clearTimeout(timeoutHandle);
            }

            reject(new DOMException('Sleep aborted.', 'AbortError'));
        }

        timeoutHandle = setTimeout(function () {
            if (signal !== null && isAbortSignal(signal)) {
                signal.removeEventListener('abort', handleAbortEvent);
            }

            resolve();
        }, duration);

        if (signal !== null && isAbortSignal(signal)) {
            signal.addEventListener('abort', handleAbortEvent, {once: true});
        }
    });
}


