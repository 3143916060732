/*
 PRODUCT: BswUserSite

 Copyright © 2023 BlueSky Wireless Limited. All rights reserved.
 */

export default class SearchClearer {
    document;

    constructor (document) {
        this.document = document;
    }

    static clearSearch (e) {
        e.preventDefault();

        if (e.target && e.target.form) {
            let foundSearchElement = false;

            for (let i = 0; i < e.target.form.length; i++) {
                if (e.target.form[i].id === 'search') {
                    e.target.form[i].value = '';
                    foundSearchElement = true;
                }
            }

            if (foundSearchElement) {
                e.target.form.submit();
            }
        }

        return false;
    }

    init () {
        const el = this.document.getElementById('clear_search');

        if (el !== null) {
            el.addEventListener('click', SearchClearer.clearSearch);
        }
    }
}
